// libraries
import CountUp from 'react-countup'
import { Link } from "react-router-dom"
import parse from 'html-react-parser'
import { useState, useEffect } from "react"
import { useTranslation } from "react-i18next"

// components & functions
import Announcements from "../elektron xidmetler/GMS/Announcements"
import { apiClient } from '../../core/api_client'
import l from "../../core/localize"
import NewsHome from "./components/NewsHome"
import PeopleLinks from "./components/PeopleLinks"
import ProjectsSlider from "./components/ProjectsSlider"
import Slider from "./components/Slider"
import UsefulLinks from "./components/UsefulLinks"

// assets
import arrow_right from "../../../assets/img/utils/arrow_right.svg"
import c1 from "../../../assets/img/c1.svg"
import c2 from "../../../assets/img/c2.svg"
import c3 from "../../../assets/img/c3.svg"
import h1 from "../../../assets/img/h1.png"
import h2 from "../../../assets/img/h2.svg"
import gm from "../../../assets/img/gm.svg"
import h3 from "../../../assets/img/h3.svg"
import kommunal from "../../../assets/img/logo/logo-kommunal.svg"
import h4 from "../../../assets/img/h4.png"
import updated_label_az from '../../../assets/img/updated_label_az.svg'
import { ProjectsModal } from '../../components/projects_modal'

const Home = () => {
	const [activeCategory, setActiveCategory] = useState<any>(undefined)
	const [categories, setCategories] = useState([])
	const { t, i18n } = useTranslation()
	const currentLang = i18n.language.slice(0, 2)

	const [isLoading, setLoading] = useState(true);


	useEffect(() => {
		apiClient.get('/projectCategories').then(res => {
			setCategories(res.data)
			if (res.data?.length > 0) {
				setActiveCategory(res.data[0])
				setLoading(false)
			}
		})
	}, [])

	useEffect(() => {
		const bodyElement = document.body as HTMLBodyElement | null;
		if (bodyElement) {
			if (isLoading) {
				bodyElement.style.overflow = 'hidden';
			} else {
				bodyElement.style.overflow = 'visible';
			}
		}
	}, [isLoading]);

	return (
		<>
			<div>
				{isLoading && <div className='d-flex justify-content-center loading-overflow' style={{ height: window.innerHeight }}><div className="lds-roller" style={{ top: 200 }}><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div></div></div>}
				{<div>
					<Slider />
					<div className="home-elektron">
						<div className="block-bg1 pt-md-4 pb-md-0 pt-5 pb-5">
							<div className="container px-md-2">
								<div className="mainpage-links block-content row mx-1 mx-md-0">
									<div className='col-2 link-box'>
										<Link to="/elektron-xidmetler/gms/gms-kategoriyalar" className="mainpage-link cursor-pointer">
											<div className="home-main-btn hover-img position-relative">
												<img src={h1} className="d-flex w-80 mx-auto" alt='Güzəştli mənzili kim ala bilər?' />
												{/* <img className='position-absolute status-label-flag' src={updated_label_az} alt="{t('common:updated')}" /> */}
											</div>
											<p className="t-color5 text-center mt-2 text-uppercase">
												{t('common:gms_ala_biler')}
											</p>
										</Link>
									</div>
									<div className='col-2 link-box'>
										<Link to="/elektron-xidmetler/satis-elanlari" className="mainpage-link cursor-pointer" >
											<div className="home-main-btn hover-img py-md-0 ps-1 position-relative">
												<img src={h2} className="d-flex w-80 mx-auto my-md-auto" alt='' />
												{/* <img className='position-absolute status-label-flag' src={updated_label_az} alt="{t('common:updated')}" /> */}
											</div>
											<p className="t-color5 text-center mt-2 text-uppercase">{t('common:announcements')}</p>
										</Link>
									</div>
									<div className='col-3 link-box'>
										<Link to="/elektron-xidmetler/gms" className="col-md-2 col-3 my-md-0 mb-2 cursor hover-img position-relative" style={{ top: '-0.5rem' }}>
											<img src={gm} className="d-flex mx-auto" id='gm-icon' alt="Güzəştli mənzil" />
										</Link>
									</div>
									<div className='col-2 link-box'>
										<a href="https://kommunal.mida.gov.az/" className="mainpage-link cursor-pointer">
											<div className="home-main-btn hover-img" style={{ backgroundColor: 'transparent', border: '2px solid #628A3F' }}>
												<img src={kommunal} className="d-flex w-80 mx-auto" alt="Kommunal" />
											</div>
											<p className="text-center mt-2 text-uppercase" style={{ color: '#628A3F' }}>
												{t('common:kommunal')}
											</p>
										</a>
									</div>
									<div className='col-2 link-box'>
										<Link to="/agentlik/hesabatlar" className="mainpage-link cursor-pointer">
											<div className="home-main-btn hover-img position-relative">
												<img src={h4} className="d-flex w-80 mx-auto" alt='Statistika' />
												{/* <img className='position-absolute status-label-flag' src={updated_label_az} alt="{t('common:updated')}" /> */}
											</div>
											<p className="t-color5 text-center mt-2 text-uppercase">{t('common:stats')}</p>
										</Link>
									</div>
								</div>
							</div>
						</div>
					</div>

					<div className="home-projects block-bg-white pt-3 pb-0">
						<div className="container px-2">
							<div className="block-content">
								<h1 className="text text-center section-heading mb-md-4">{t('projects:projects')}</h1>
								<div className="breadcrumb page-link mt-sm-0 mt-lg-5 mt-md-2 mt-4 mb-2">
									<div className="container p-0 ">
										<div className="line"></div>
										<div className="bc-items d-flex col-12 justify-content-between">
											{categories.map((category: any) =>
												<p key={category.id}
													onClick={_ => setActiveCategory(category)}
													className={
														"col-12 col-md-3 bc-button fw-normal m-0 " +
														(activeCategory?.id === category.id && "bc-button-active")
													}
												>
													{l(category, "name", currentLang)}
												</p>
											)}
										</div>
									</div>
								</div>
								<div className="mt-2 mt-sm-5">
									{activeCategory &&
										<ProjectsSlider categoryId={activeCategory.id} />
									}
								</div>
							</div>
						</div>
					</div>

					<div className="home-stats pt-2 block-bg-white cursor">
						<div className="container m-md-auto px-md-2">
							{/* <ProjectsModal className="block-content row justify-content-md-center justify-content-between px-3 px-md-0"> */}
							<div className="block-content row justify-content-md-center justify-content-between px-3 px-md-0">
								<div className="home-stats-item col-sm-3 col-3 d-flex my-sm-0 my-3 mx-md-4 justify-content-center align-items-center p-0">
									<img src={c1} style={{ width: "100px", height: "100px" }} className='me-2' alt='' />
									<div className="ms-md-2 me-md-2">
										<small className="t-color1">
											{parse(t('common:all_proj_count'))}
										</small>
										<h2
											className="t-color5"
											style={{ fontSize: "28px", fontWeight: 700 }}
										>
											<CountUp start={1} end={13} duration={5} redraw={true} />
										</h2>
									</div>
								</div>
								<div className="home-stats-item col-sm-3 col-3 d-flex flex-nowrap my-sm-0 my-3 mx-md-4 justify-content-center align-items-center p-0">
									<img src={c2} style={{ width: "100px", height: "100px" }} className='me-2' alt='' />
									<div className="ms-md-2 me-md-2">
										<small className="t-color1">
											{parse(t('common:done_apts_count'))}
										</small>
										<h2
											className="t-color5"
											style={{ fontSize: "28px", fontWeight: 700 }}
										>
											<CountUp start={1} end={11087} duration={3} redraw={true} separator=" " />
										</h2>
									</div>
								</div>
								<div className="home-stats-item col-sm-3 col-4 d-flex flex-nowrap my-sm-0 my-3 mx-md-4 justify-content-center align-items-center p-0">
									<img src={c3} style={{ width: "100px", height: "100px" }} className='me-2' alt='' />
									<div className="ms-md-2 me-md-2">
										<small className="t-color1">
											{parse(t('common:apts_taken_count'))}
										</small>
										<h2
											className="t-color5"
											style={{ fontSize: "28px", fontWeight: 700 }}
										>
											<CountUp start={1} end={8925} duration={3} redraw={true} separator=" " />
										</h2>
									</div>
								</div>
							</div>
							{/* </ProjectsModal> */}
							<Link
								to="/layiheler"
								className="btn-container d-flex justify-content-end mt-2 mb-4">
								<span className="more-info-btn">
									<small>{t('projects:all_projects')}</small>
									<img className="ms-2" src={arrow_right} alt="" />
								</span>
							</Link>
						</div>
					</div>

					<div className="home-announcements block-bg1 pt-4 pb-3">
						<div className="container px-2">
							<div className="block-content">
								<h1 className="text text-center section-heading">{t('common:announcements')}</h1>
								<div className="m-2 mt-3 mb-2 row justify-content-between">
									<Announcements />
								</div>
								<div className="btn-container d-flex justify-content-end my-md-3 mt-3">
									<Link to="/elektron-xidmetler/satis-elanlari" className="more-info-btn">
										<small>{t('common:all_announcements')}</small>
										<img className="ms-2" src={arrow_right} alt="" />
									</Link>
								</div>
							</div>
						</div>
					</div>
					<div className="home-news block-bg1 block-bg-white py-3">
						<div className="container px-2">
							<h1 className="text text-center section-heading mt-md-3">{t('common:news')}</h1>
							<NewsHome />
						</div>
					</div>
					<div className="home-biglinks block-bg1 pt-md-60 pb-md-80 py-4">
						<div className="container px-lg-2">
							<PeopleLinks />
							<div className="mt-md-5 flex-wrap">
								<UsefulLinks />
							</div>
						</div>
					</div>
				</div>
				}
			</div>
		</>
	)
}

export default Home 
